@keyframes pulse {
    from {
        background-color: rgba(0, 0, 0, 0)
    }
    to {
        background-color: rgba(255, 0, 0, 0.4)
    }
}

.challenge-highlight {
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease;
}


.statistic-title:hover {
    cursor: pointer;
}
