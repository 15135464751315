.rank-image {
    width: 32px;
    height: 32px;
    position: relative;
}

.rank-image-division {
    position: absolute;
    bottom: 0;
    right: 0;
    text-shadow:
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000,
            1px 1px 0 #000;
    line-height: 0;
}

.ant-statistic-content .rank-image-division {
    font-size: 16px;
}
